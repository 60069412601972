import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

export default class LineText {
  constructor(rootNode) {
    this.root = rootNode;

    this.initAnimation();
  }

  initAnimation() {
    const items = this.root.querySelectorAll('.item');
    items.forEach((item) => {
      ScrollTrigger.create({
        trigger: item,
        // markers: true,
        scrub: true,
        start: 'top 70%',
        onEnter: () => {
          item.classList.add('active');
        },
      });
    });
  }
}
