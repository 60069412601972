import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

export default class TechnologyGrid {
  constructor(rootNode) {
    this.root = rootNode;

    if(innerWidth > 1024){
      this.initAnimation();
    }

  }

  initAnimation() {
    gsap.from(this.root.querySelectorAll('.technology'), {
      yPercent: 10,
      opacity: 0,
      stagger: 0.1,
      scrollTrigger: {
        trigger: this.root,
        start: 'top center',
      }
    })
  }
}
