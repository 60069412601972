import gsap from 'gsap';

export default class Header {
  constructor(rootNode) {
    this.root = rootNode;

    this.button = this.root.querySelector('button[aria-controls="navigation"]');
    this.nav = this.root.querySelector('nav');

    this.initMenuAnimation();
    this.initScrollListener();
  }

  initScrollListener() {
    setInterval(() => {
      this.root.classList.toggle('scroll', scrollY > 0);
    }, 100);
  }

  initMenuAnimation() {
    let mm = gsap.matchMedia();

    mm.add('(max-width: 1024px)', () => {
      this.openNavAnimation = gsap.timeline({ paused: true });
      this.openNavAnimation.set(this.nav, { display: 'flex' });
      this.openNavAnimation.to(this.nav, { x: 0 }, 'p');

      const lines = this.button.querySelectorAll('*');
      this.openNavAnimation.to(lines[0], { rotate: 45 }, 'p');
      this.openNavAnimation.to(lines[1], { scaleX: 0, opacity: 0 }, 'p');
      this.openNavAnimation.to(lines[2], { rotate: -45, y: 1.5 }, 'p');

      const items = this.nav.querySelectorAll('.nav-item');
      this.openNavAnimation.to(items, { opacity: 1, y: 0, stagger: 0.1 });
    });

    this.button.addEventListener('click', () => {
      this.isNavExpanded ? this.closeNav() : this.openNav();
    });
  }

  closeNav() {
    this.isNavExpanded = false;
    this.button.setAttribute('aria-expanded', 'false');
    this.nav.setAttribute('data-state', 'closed');
    this.openNavAnimation.reverse();
  }

  openNav() {
    this.isNavExpanded = true;
    this.button.setAttribute('aria-expanded', 'true');
    this.nav.setAttribute('data-state', 'open');
    this.openNavAnimation.play();
  }
}
