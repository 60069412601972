import Swiper from 'swiper';

export default class ProjectTeaser {
  constructor(rootNode) {
    this.root = rootNode;

    this.initSlider();
  }

  initSlider() {
    new Swiper(this.root.querySelector('.swiper'), {
      spaceBetween: 40,
      slidesPerView: 1,
      breakpoints: {
        1024: {
          enabled: false,
        },
      },
    });
  }
}
