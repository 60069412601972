import * as components from './components';
import './background';
import './lozad';
import { animate } from './loading-animation';

const Website = window.Website || {
  vars: {},
  methods: {
    initializeComponents: () => {
      const componentNodes =
        Array.from(document.querySelectorAll('[data-component]')) || [];
      componentNodes.forEach((node) => {
        const componentName = node.getAttribute('data-component');
        let props = node.getAttribute('data-props');
        if (props) props = JSON.parse(props);

        if (!!components[componentName]) {
          /* eslint-disable no-new */
          new components[componentName](node, props);
        }
      });
    },
    animate,
  },
};

// load/initialize complex js-components
document.addEventListener('DOMContentLoaded', () => {
  Website.methods.initializeComponents();
  Website.methods.animate();
});
