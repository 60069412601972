import gsap from "gsap";

export function animate() {
  const animation = gsap.timeline({
    defaults: { duration: 0.8, ease: "power2.out" },
  });

  for (let i = 1; i <= 10; i++) {
    const item = document.querySelector(`[data-loading-animation="${i}"]`);
    if (!item) {
      break;
    }
    const target = item.hasAttribute("data-loading-animation-stagger") ? item.children : item;

    const delay = i > 1 ? "-=0.6" : "";

    animation.to(
      target,
      {
        y: 0,
        opacity: 1,
        stagger: 0.1,
      },
      delay
    );
  }
}
