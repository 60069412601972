const SPEED = 100; // px/s

import gsap from 'gsap';

const circles = document.querySelectorAll('.bg-container .circle');

function setInitialPosition(circle) {
  gsap.set(circle, { x: Math.random() * innerWidth });
  gsap.set(circle, { y: Math.random() * innerHeight });
}

function animate(circle) {
  const currentX = gsap.getProperty(circle, 'x');
  const currentY = gsap.getProperty(circle, 'y');

  const newX = Math.random() * innerWidth;
  const newY = Math.random() * innerHeight;

  const distance = getDistanceBetweenToPoints(currentX, currentY, newX, newY);
  gsap.to(circle, {
    ease: 'none',
    duration: distance / SPEED,
    x: newX,
    y: newY,
    onComplete: (x) => {
      animate(circle);
    },
  });
}

function getDistanceBetweenToPoints(x1, y1, x2, y2) {
  const differenceX = Math.abs(x2 - x1);
  const differenceY = Math.abs(y2 - y1);
  const distance = Math.sqrt(
    differenceX * differenceX + differenceY * differenceY
  );
  return distance;
}

if (circles.length) {
  circles.forEach((circle) => {
    setInitialPosition(circle);
    animate(circle);
  });
}
