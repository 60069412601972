import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

export default class Quote {
  constructor(rootNode) {
    this.root = rootNode;

    this.initAnimation();
  }

  initAnimation() {
    const animation = gsap.timeline();
    animation.from(this.root.querySelectorAll('.author'), {
      opacity: 0,
    });

    ScrollTrigger.create({
      animation,
      trigger: this.root,
      scrub: true,
      start: 'top center',
      end: '50% center',
    });
  }
}
