import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default class ServiceTeaser {
  constructor(rootNode) {
    this.root = rootNode;

    if (innerWidth > 1024) {
      this.initAnimation();
    }
  }

  initAnimation() {
    const cards = this.root.querySelectorAll(".card");

    cards[0].style.transformOrigin = "right center";
    cards[1].style.transformOrigin = "left center";

    const animation = gsap.timeline();

    animation.from(cards, {
      scale: 0.5,
      opacity: 0,
      stagger: 0.1,
    });

    ScrollTrigger.create({
      animation,
      trigger: this.root,
      start: "top center",
    });
  }
}
