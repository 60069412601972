export default class LogoScroll {
  constructor(rootNode) {
    this.root = rootNode;
    this.duration = this.root.dataset.duration * 1000;

    this.container = this.root.querySelector(".outer-container");
    this.distance = this.root.querySelector(".inner-container").getBoundingClientRect().width + 40; // gap-x-10

    this.cloneLogoContainer();
    this.initLogoScroll();
  }

  cloneLogoContainer() {
    const innerContainer = this.root.querySelector(".inner-container");

    this.container.appendChild(innerContainer.cloneNode(true));
  }

  initLogoScroll() {
    this.container.style.transition = `transform ${this.duration}ms linear`;
    this.container.style.transform = `translateX(-${this.distance}px)`;

    setInterval(() => {
      this.container.style.transition = `none`;
      this.container.style.transform = `translateX(0)`;
      this.container.scrollHeight;
      this.container.style.transition = `transform ${this.duration}ms linear`;
      this.container.style.transform = `translateX(-${this.distance}px)`;
    }, this.duration);
  }
}
