import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

export default class Text {
  constructor(rootNode) {
    this.root = rootNode;

    this.splitWords();
    this.initAnimation();
  }

  splitWords() {
    const p = this.root.querySelector('p');
    const text = p.innerText;

    const words = text.split(' ');

    p.innerHTML = '';

    words.forEach((word) => {
      const outerSpan = document.createElement('span');
      outerSpan.classList.add('word-container');

      const innerSpan = document.createElement('span');

      innerSpan.innerHTML = word + '&nbsp;';

      outerSpan.appendChild(innerSpan);
      p.appendChild(outerSpan);
    });
  }

  initAnimation() {
    const animation = gsap.timeline();

    animation.from(this.root.querySelectorAll('.word-container > span'), {
      yPercent: 100,
      opacity: 0,
      rotate: 10,
      stagger: 0.1,
    });

    ScrollTrigger.create({
      animation,
      trigger: this.root,
      // markers: true,
      scrub: true,
      start: 'top 80%',
      end: 'bottom 80%',
    });
  }
}
