export default class Accordion {
  constructor(rootNode) {
    this.root = rootNode;

    this.initAccordion();
  }

  initAccordion() {
    const items = this.root.querySelectorAll(".accordion-item");

    items.forEach((item) => {
      const header = item.querySelector(".accordion-header");
      const content = item.querySelector(".accordion-content");

      let isOpened = false;

      header.addEventListener("click", () => {
        content.classList.toggle("grid-rows-accordion-open", !isOpened);
        content.classList.toggle("grid-rows-accordion-close", isOpened);

        item.classList.toggle("open", !isOpened);

        isOpened = !isOpened;
      });
    });
  }
}
