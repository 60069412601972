import gsap from 'gsap';

import ScrollTrigger from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

export default class Contact {
  constructor(rootNode) {
    this.root = rootNode;

    this.addScrollAnimation();
  }

  addScrollAnimation() {
    const items = this.root.querySelectorAll('.list .number');

    items.forEach((item) => {
      const counter = document.getElementById('counter');
      const value = {
        val: parseInt(item.innerText),
      };

      gsap.from(value, {
        duration: 1.5,
        ease: 'circ.out',
        val: 0,
        roundProps: 'val',
        onUpdate: function () {
          item.innerText = value.val
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, '.');
        },
        scrollTrigger: {
          trigger: item,
          start: 'top bottom',
        },
      });
    });
  }
}
